// stylelint-disable

@font-face {
    font-family: 'Libre Caslon Normal Text';
    src: url('./fonts/LibreCaslonText-Regular.woff2') format('woff2'),
        url('./fonts/LibreCaslonText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Caslon Italic Text';
    src: url('./fonts/LibreCaslonText-Italic.woff2') format('woff2'),
        url('./fonts/LibreCaslonText-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Libre Caslon Display';
    src: url('./fonts/LibreCaslonDisplay-Regular.woff2') format('woff2'),
        url('./fonts/LibreCaslonDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gothic Semi Bold A1';
    src: url('./fonts/GothicA1-SemiBold.woff2') format('woff2'),
        url('./fonts/GothicA1-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gothic A1';
    src: url('./fonts/GothicA1-Regular.woff2') format('woff2'),
        url('./fonts/GothicA1-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

//New Fonts
$font-main: 'Gothic Semi Bold A1', sans-serif;
$font-alt: 'Libre Caslon Display', serif;
$font-alt1: 'Libre Caslon Normal Text', serif;
$font-alt2: 'Libre Caslon Italic Text', serif;
$font-alt3: 'Gothic A1', sans-serif;