/*md

# g-button_main*

# Mixin for main button.

```scss_example
.b-button {
	@include g-button_main($_mod: default);
}
```
*/

@mixin g-button_main($_mod: default) {
	@if $_mod == default {
		@include g-button_global;

		background: var(--button_main-background_color, $color-button_main-background);
		border: $configuration-button_main-border_width solid var(--button_main-border_color, $color-button_main-border);
		color: var(--button_main-text_color, $color-button_main-text);
		font-family: $font-alt3;
		font-weight: normal;

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background: var(--button_main__hover-background_color, $color-button_main__hover-background);
				border-color: var(--button_main__hover-border_color, $color-button_main__hover-border);
				color: var(--button_main__hover-text_color, $color-button_main__hover-text);
			}
		}

		&:disabled:not(.m-processing),
		&.m-disabled:not(.m-processing) {
			@include g-button_global($_mod: disabled);

			opacity: $configuration-button_main__disabled-opacity;
		}

		&.m-small {
			@include g-button_global($_mod: small);
		}

		&.m-width_s {
			@include g-button_global($_mod: width_s);
		}

		&.m-width_m {
			@include g-button_global($_mod: width_m);
		}

		&.m-width_l {
			@include g-button_global($_mod: width_l);
		}

		&.m-processing {
			cursor: wait;
		}
	}

	@if $_mod == agegate {
		@include g-button_global;

		border: $configuration-button_main-border_width solid $color-primary-background;
		color: $color-primary-background;

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background: var(--button_main__hover-background_color, $color-primary-background);
				color: var(--button_main__hover-text_color, $color-primary);
			}
		}
	}

	@if $_mod == inversed {
		background: var(--button_main__inversed-background_color, $color-button_main__inversed-background);
		border: $configuration-button_main-border_width solid var(--button_main__inversed-border_color, $color-button_main__inversed-border);
		color: var(--button_main__inversed-text_color, $color-button_main__inversed-text);

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background: var(--button_main__inversed__hover-background_color, $color-button_main__inversed__hover-background);
				border-color: var(--button_main__inversed__hover-border_color, $color-button_main__inversed__hover-border);
				color: var(--button_main__inversed__hover-text_color, $color-button_main__inversed__hover-text);
			}
		}

		&:disabled:not(.m-processing),
		&.m-disabled:not(.m-processing) {
			opacity: 0.5;
		}
	}

	@if $_mod == full_width {
		@include g-button_global($_mod: full_width);
	}
}
